// frontend/src/components/NavBar.js

import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './NavBar.css';
import { UserContext } from '../context/UserContext';

import logo from '../assets/logo.jpg';
import cartIcon from '../assets/cart-icon.png';

const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const { user, logout } = useContext(UserContext);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  return (
    <nav className="navbar" role="navigation" aria-label="Main Navigation">
      <div className="logo-container">
        <Link to="/" className="logo-link">
          <img src={logo} alt="ShipGopher Logo" className="logo-img" />
          <span className="logo-text">ShipGopher</span>
        </Link>
      </div>
      <div className={`nav-links ${isMobileMenuOpen ? 'active' : ''}`}>
        <Link to="/get-quote" className="nav-link">
          Get a Quote
        </Link>
        <Link to="/track-shipment" className="nav-link">
          Track Shipment
        </Link>
        {user ? (
          <>
            <span className="nav-link welcome-message">
              Welcome, {user.username || user.email}
            </span>
            <Link to="/cart" className="nav-link">
              <img src={cartIcon} alt="Cart" className="cart-icon" />
            </Link>
            <button onClick={logout} className="nav-link logout-button">
              Logout
            </button>
          </>
        ) : (
          <>
            <Link to="/login" className="nav-link">
              Login
            </Link>
            <Link to="/register" className="nav-link highlight">
              Register
            </Link>
          </>
        )}
      </div>
      <button
        className="mobile-menu-icon"
        onClick={toggleMobileMenu}
        aria-label="Toggle Navigation Menu"
      >
        <span className="hamburger"></span>
      </button>
    </nav>
  );
};

export default NavBar;
