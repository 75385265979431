// frontend/src/context/CartContext.js

import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import apiClient from '../utils/apiClient';
import { UserContext } from './UserContext';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const { user } = useContext(UserContext);

  const fetchCartItems = useCallback(async () => {
    if (!user) {
      setCartItems([]);
      return;
    }

    try {
      const response = await apiClient.get('/cart');
      setCartItems(response.data);
    } catch (err) {
      console.error('Error fetching cart items:', err);
    }
  }, [user]);

  useEffect(() => {
    fetchCartItems();
  }, [fetchCartItems]);

  const addToCart = async (labelDetails) => {
    if (!user) {
      // Redirect to login if user is not authenticated
      // Or handle it as per your application's flow
      return;
    }

    try {
      const response = await apiClient.post('/cart', { labelDetails });
      setCartItems((prevItems) => [...prevItems, response.data]);
    } catch (err) {
      console.error('Error adding to cart:', err);
    }
  };

  const removeFromCart = async (id) => {
    try {
      await apiClient.delete('/cart', { data: { id } });
      setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
    } catch (err) {
      console.error('Error removing from cart:', err);
    }
  };

  const clearCart = async () => {
    try {
      // Remove items one by one
      const removePromises = cartItems.map((item) => removeFromCart(item.id));
      await Promise.all(removePromises);
      setCartItems([]);
    } catch (err) {
      console.error('Error clearing cart:', err);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        fetchCartItems,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
