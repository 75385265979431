// frontend/src/App.js

import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import { CartProvider } from './context/CartContext';
import { UserProvider } from './context/UserContext';
import NavBar from './components/NavBar';
import './styles/global.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Home = lazy(() => import('./pages/home'));
const ShippingForm = lazy(() => import('./components/ShippingForm'));
const TrackingForm = lazy(() => import('./components/TrackingForm'));
const Cart = lazy(() => import('./components/Cart'));
const Register = lazy(() => import('./pages/Register'));
const Login = lazy(() => import('./pages/Login'));

function App() {
  return (
    <Router>
      <UserProvider>
        <CartProvider>
          <ErrorBoundary>
            <NavBar />
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/get-quote" element={<ShippingForm />} />
                <Route path="/track-shipment" element={<TrackingForm />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </CartProvider>
      </UserProvider>
    </Router>
  );
}

export default App;
