import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for props validation

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    // Update state to display fallback UI
    return { hasError: true };
  }

  componentDidCatch(_error, errorInfo) {
    // Prefix unused 'error' with '_'
    // Log error details
    console.error('ErrorBoundary caught an error', _error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

// Define propTypes for props validation
ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
